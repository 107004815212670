import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { WithStyles } from "@material-ui/core";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { Document, User } from "../../projecttemplates/src/ProjectTemplatesDealDashboardController.web";
import { MenuAction } from "../../../components/src/CustomMenu";
import { downloadProgress, downloadComplete, errorOutline } from "./assets";

interface Note {
  id: string;
  note: string;
  note_type: string;
  updated_time: string;
  updated_date: string;
  dealership_name: string;
  is_auto_note: boolean;
  account: {
    id: string;
    full_name: string;
    role: string;
    profile_picture: string;
  };
}

export interface IssueRaised{
  filename: string,
  issue_raised_at: string,
  subject: string,
  details: string
}

interface FileUploaderState {
  [key: string]: number; 
}

export const formattedDate = (timestamp: string) => {
  const date = new Date(timestamp);

  return `${
    date.getMonth() + 1
  }/${date.getDate()}/${date.getFullYear()}`;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles<any> {
  navigation: any;
  id: string;
  // Customizable Area Start
  user: User;
  isSPHired: boolean;
  basicInfos: { [key: string]: any };
  documents: Document[];
  getDealInformation: () => void;
  fetchDocuments: () => void;
  accepted_selected_service_provider_id: string;
  manageReteriewDocumnet: (value: boolean) => void;
  isDealCompletedOrDeleted: boolean;
  navigateToAutoForm?: () => void;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  selectedFiles: File[];
  selectedFile: Document;
  filteredDocuments: Document[];
  isUploadingDocuments: boolean;
  showProgress: boolean;
  edittingDocument: Document;
  compareDocMode: boolean;
  note: Note;
  raiseIssue: boolean;
  showIssueRaised: boolean;
  issueRaised: IssueRaised;
  uploadProgress:  FileUploaderState;
  selectedStatus: string;
  searchKey: string;
  remainingEditable: undefined | number;
  transactionId: string;
  isEditDocuments: boolean;
  zoom:number
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class ProjectTemplatesDocumentsUploadController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  uploadFilesApiId: string = '';
  renameFileApiId: string = '';
  deleteFileApiId: string = '';
  getIssueRaisedApiId: string = '';
  downloadAllFilesApiId: string = '';
  apiChangeDocumentStatus: string = '';
  editDocumentsApiId: string = '';

  fileIcon = (file: Document) => {
    switch (file.status) {
      case "ready":
        return downloadComplete;
      case "approved":
        return downloadComplete;
      case "not_ready":
        return errorOutline;
      case "issue_raised":
        return errorOutline;
      default:
        return downloadProgress;
    }
  }

  handleAddSelectedFiles(selectedFiles: File[]) {
    const files = this.state.selectedFiles;
    const filteredDuplicatedFiles = selectedFiles.filter(
      (selectFile) => !files.some((file) => file.name === selectFile.name)
    );
    this.setState({
      selectedFiles: [...files, ...filteredDuplicatedFiles],
    },
    () => {
      filteredDuplicatedFiles.forEach((file) => this.uploadFile(file));
    }
  );
  }

  handleStopUploadingDocuments = () => {
    this.setState({
      isUploadingDocuments: false,
      selectedFiles: [],
      uploadProgress: {}
    });
  };

  handleUploadingDocuments = () => {
    if (this.props.isDealCompletedOrDeleted) {
      return;
    }
    this.setState({
      isUploadingDocuments: true,
    });
  };

  uploadFile = (file: File) => {
    const fileSizeMB = file.size / (1024 * 1024);
    const uploadSpeed = Math.max(20, Math.min(200, 100 - fileSizeMB * 5)); // Faster updates
    const stepSize = fileSizeMB < 1 ? 5 : 3; // Smaller, more frequent steps
  
    this.setState((prevState) => ({
      uploadProgress: { ...prevState.uploadProgress, [file.name]: 0 },
    }));
  
    const interval = setInterval(() => {
      this.setState((prevState) => {
        const newProgress = { ...prevState.uploadProgress };
  
        if (newProgress[file.name] < 100) {
          newProgress[file.name] = Math.min(100, newProgress[file.name] + stepSize);
        } else {
          clearInterval(interval);
        }
  
        return { uploadProgress: newProgress };
      });
    }, uploadSpeed); 
  };  

  handleDeleteFile(deleteFile: File) {
    const filteredFiles = this.state.selectedFiles.filter(
      (file) => file.name !== deleteFile.name
    );

    const filteredUploadProgress = { ...this.state.uploadProgress };
    delete filteredUploadProgress[deleteFile.name];

    this.setState({
      selectedFiles: filteredFiles,
      uploadProgress: filteredUploadProgress,
    });
  }

  handleSelectFile(file: Document) {
    this.setState({
      selectedFile: file,
    }, () => {
      if(this.state.selectedFile.status === 'issue_raised'){
        this.getIssueRaised();
      }
    });
  }

  getIssueRaised = () => {
    const webHeader = {
      token: localStorage.getItem('authToken'),
    };
    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getIssueRaisedApiId = webRequestMessage.messageId;
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      '/bx_block_posts/deal_document_issues/issue_raised?document_id=' + this.state.selectedFile.id
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
    return true;
  }

  getEditDocuments = () => {
    const webHeader = {
      token: localStorage.getItem('authToken'),
    };
    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.editDocumentsApiId = webRequestMessage.messageId;
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      '/bx_block_posts/deals/edit_documents?id=' + localStorage.getItem('createdDealId')
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
    return true;
  }

  handleFilterDocuments(event: any) {
    this.setState({
      searchKey: event.target.value
    })
  }

  downloadAllDocuments = (url: string) => {
    window.open(url);
  };

  changeSelectedDocumentStatus = (status: string) => {
    this.setState({ selectedFile: { ...this.state.selectedFile, status: status } })
  }

  openRaiseIssue = (issue?:string) => {
    if(issue==='issue_raised'){
      this.setState({ showIssueRaised: true })

    }else{
      this.setState({ raiseIssue: true,showIssueRaised:false })

    }
  }
  getWindowStatus = (value: boolean) => {
    this.setState({ raiseIssue: value }, () => {
      this.props.manageReteriewDocumnet(value)
    })

  }

  changeDocumentStatus = (status: string, id: string) => {
    const webHeader = {
      'Content-Type': 'application/json',
      token: localStorage.getItem('authToken'),
    };
    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiChangeDocumentStatus = webRequestMessage.messageId;
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'bx_block_posts/deals/update_docuemnt_status/' + localStorage.getItem('createdDealId')
    );
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        deal_document_id: id,
        status: status
      })
    );
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'PUT'
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
    return true;
  }

  selectDocumentAction = (action: string, file: Document) => {
    if (action === 'rename') {
      this.handleRenameDocument(file);
    }
    if (action === 'delete') {
      this.handleDeleteDocument(file);
    }
    if (action === 'Reupload') {
      this.handleDeleteDocument(file);
      setTimeout(() => {
        this.handleUploadingDocuments()
      }, 1000)
    }
  }

  handleRenameDocument(file: Document) {
    this.setState({
      edittingDocument: file
    })
  }


  renameFile = (event: any) => {
    let fileName = event.target.value;
    const currentFileName = this.state.edittingDocument.filename;
    const currentExtension = currentFileName.includes('.')
      ? currentFileName.substring(currentFileName.lastIndexOf('.'))
      : '.pdf';
    const newFileName = fileName.replace(/\.[^/.]+$/, "");
    this.setState(prevState => ({
      edittingDocument: {
        ...prevState.edittingDocument,
        filename: newFileName + currentExtension,
      },
    }));

    if (event.key === 'Enter') {
      this.setState(prevState => ({
        edittingDocument: {
          ...prevState.edittingDocument,
          filename: prevState.edittingDocument.filename, // Keep the modified filename with the same extension
        },
      }), this.handleRenameEdittingDocument);
    }
  }
  handleRenameEdittingDocument = () => {
    const formData = new FormData();
    formData.append('deal_document_id', this.state.edittingDocument.id);
    formData.append('document_name', this.state.edittingDocument.filename);

    const webHeader = {
      token: localStorage.getItem('authToken'),
    };
    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.renameFileApiId = webRequestMessage.messageId;
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'bx_block_posts/deals/update_document/' + localStorage.getItem('createdDealId')
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'PATCH'
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
    return true;
  }

  handleDownloadAllDocuments = (value: MenuAction) => {
    if (value === MenuAction.DownloadAllDocuments) {
      const webHeader = {
        'Content-Type': 'application/json',
        token: localStorage.getItem('authToken'),
      };
      const webRequestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.downloadAllFilesApiId = webRequestMessage.messageId;
      webRequestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        'bx_block_posts/deals/download_deal_documents/' + localStorage.getItem('createdDealId')
      );

      webRequestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(webHeader)
      );

      webRequestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        'GET'
      );
      runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
      return true;
    }

    if (value === MenuAction.EditDocuments){
      this.setState({
        isEditDocuments: true
      })
    }
  }

  handleEditDocuments = () => {
      this.props.navigateToAutoForm && this.state.remainingEditable !== 0 && this.props.navigateToAutoForm();
  }

  handleDeleteDocument(file: Document) {
    const formData = new FormData();
    formData.append('deal[deal_document_id]', file.id);

    const webHeader = {
      token: localStorage.getItem('authToken'),
    };
    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.uploadFilesApiId = webRequestMessage.messageId;
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'bx_block_posts/deals/delete_deal_documents/' + localStorage.getItem('createdDealId')
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'DELETE'
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
    return true;
  }

  uploadFiles(selectedFiles: File[]) {
    this.setState({ showProgress: true });

    const formData = new FormData();
    formData.append('id', localStorage.getItem('createdDealId')!);
    selectedFiles.forEach(file => formData.append('deal_documents[]', file))

    const webHeader = {
      token: localStorage.getItem('authToken'),
    };
    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.uploadFilesApiId = webRequestMessage.messageId;
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'bx_block_posts/deals/upload_documents'
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'POST'
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);

    const noteContent = selectedFiles.length > 1
      ? "Documents uploaded successfully"
      : "Document uploaded successfully";

    const payload = {
      note: {
        ...this.state.note,
        note: noteContent
      } as Note
    };

    this.setState(prevState => ({
      note: {
        ...prevState.note,
        note: noteContent
      } as Note
    }));

    return true;
  }

  triggerCompareDoc = () => {
    this.setState({
      compareDocMode: !this.state.compareDocMode
    })
  }
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.state = {
      selectedFiles: [],
      filteredDocuments: this.props.documents,
      selectedFile: {} as Document,
      isUploadingDocuments: false,
      showProgress: false,
      edittingDocument: {} as Document,
      compareDocMode: false,
      note: {
        id: '',
        note: '',
        note_type: 'internal',
        updated_time: '',
        updated_date: '',
        dealership_name: '',
        is_auto_note: false,
        account: {
          id: '',
          full_name: '',
          role: '',
          profile_picture: ''
        }
      },
      raiseIssue: false,
      showIssueRaised: false,
      issueRaised: {} as IssueRaised,
      uploadProgress: {},
      selectedStatus: 'all',
      searchKey: '',
      remainingEditable: undefined,
      transactionId: '',
      isEditDocuments: false,
      zoom:100
    };

    this.handleFilterDocuments = this.handleFilterDocuments.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
    ];
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    this.getEditDocuments();
    // Customizable Area End
  }

  // Customizable Area Start
  componentDidUpdate(prevProps: Props, prevState: Readonly<S>) {
    if (prevProps.documents !== this.props.documents) {
      this.setState({ filteredDocuments: this.props.documents });
    }
  }  
  reteriewDocumentStatus = (value: boolean) => {
    if (value) {
      this.props.fetchDocuments();
      this.changeSelectedDocumentStatus('issue_raised');
      this.props.manageReteriewDocumnet(true)

    }

  }

  handleZoomIn=()=>{
    this.setState((prevState) => ({ zoom: Math.min(prevState.zoom + 10, 200) }));
  }
  handleZoomOut = (): void => {
    this.setState((prevState) => ({ zoom: Math.max(prevState.zoom - 10, 50) }));
  };
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let response = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (response && !response.errors) {
        switch (apiId) {
          case this.uploadFilesApiId:
            this.setState({ showProgress: false });
            this.props.getDealInformation();
            break;
          case this.renameFileApiId:
            this.setState({
              edittingDocument: {} as Document,
            })
            this.props.getDealInformation();
            break;
          case this.deleteFileApiId:
            this.props.getDealInformation();
            break;
          case this.downloadAllFilesApiId:
            this.downloadAllDocuments(response.url);
            break;
          case this.apiChangeDocumentStatus:
            this.props.fetchDocuments();
            break;
          case this.getIssueRaisedApiId:
            this.setState({
              issueRaised: response.issue_raised,
              raiseIssue:false
            })
            break;
          case this.editDocumentsApiId:
            if(!response.error){
              this.setState({
                remainingEditable: response.remaining_usage_count,
                transactionId: response.transaction_id
              })
            } 
        }
      }

    }
    // Customizable Area End
  }
}
