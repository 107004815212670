import React from "react";
// Customizable Area Start
import {
  Box,
  withStyles,
  createStyles,
  Button,
  FormControl,
  Input,
  InputAdornment,
  Card,
  Typography,
  createTheme,
  ThemeProvider,
  AccordionSummary,
  AccordionDetails,
  Select,
  MenuItem,
  SvgIcon,
  IconButton,
  CircularProgress,
  Tooltip,
  Checkbox,
  styled,
  Dialog,
  DialogContent,
  DialogActions,
  TextField
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ProjectTemplatesDealDashboardController, {
  Props,
  configJSON,
} from "./ProjectTemplatesDealDashboardController.web";
import { vitu, filterIcon } from "./assets";
import RegistrationFee from "./RegistrationFee";
import SearchIcon from "@material-ui/icons/Search";
import ServiceProviderMenu from "./ServiceProviderMenu";
import ServiceProviderHired from "./ServiceProviderHired";
import ServiceProviderHiring from "./ServiceProviderHiring";
import RegistrationFeeDrawer from "./RegistrationFeeDrawer";
import CustomInfoComponent from "../../../components/src/CustomInfoComponent";
import DealDashboardNote from "../../projectnotes/src/DealDashboardNote.web";
import DealDashboardTaskList from "../../tasks/src/DealDashboardTaskList.web";
import ProfileDropdown from "../../customisableuserprofiles/src/ProfileDropdown.web";
import HamburgerMenuFull from "../../customisableuserprofiles/src/HamburgerMenuFull.web";
import ProjectTemplatesDocumentsUpload from "../../bulkuploading/src/ProjectTemplatesDocumentsUpload.web";
import Chat from "../../chat/src/Chat.web";
import clsx from "clsx";
import ViewChat from "../../chat/src/ViewChat.web";
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordion from '@material-ui/core/Accordion';
import Pushnotifications from "../../pushnotifications/src/Pushnotifications.web";
import { Close, ThreeSixty } from "@material-ui/icons";
import CircleCheckedFilled from '@material-ui/icons/CheckCircle';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import ClearIcon from "@material-ui/icons/Clear";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';
const Accordion1 = withStyles((theme) => ({
  root: {
    background: "#F1F4F3CC",
    boxShadow: "0px 0px 4px 0px #8989895C",
    padding: "15px 20px",
    borderRadius: 10,
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
      padding: "20px"
    },
    [theme.breakpoints.up(1700)]: {
      padding: "40px 30px 35px 30px"
    },

  },
  expanded: {},
}))(MuiAccordion);

const RightSideAccordion = withStyles((theme) => ({
  root: {
    background: "#F1F4F3CC",
    boxShadow: "0px 0px 4px 0px #8989895C",
    padding: "20px",
    borderRadius: 10,
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
      padding: "20px"
    },
    [theme.breakpoints.up(1920)]: {
      padding: "35px 20px"
    },

  },
  expanded: {},
}))(MuiAccordion);


const AccordionSummary1 = withStyles({
  root: {
    minHeight: 40,
    '&$expanded': {
      minHeight: 40,
    },
  },
  content: {
    '&$expanded': {
      margin: '0px',
    },
  },
  expanded: {},
})(MuiAccordionSummary);


const classNames = createStyles({
  layout: {
    display: 'flex',
    height: '100%',
    width: '100%',
  },

  container: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: "100%",
    // minWidth: "1366px",
  },

  header: {
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "12px 32px",
    borderBottom: "1px solid #F0FAFA",
  },

  headerLeftSide: {
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
    gap: "48px",
  },

  headerMenuLogo: {
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
    gap: "8px",
  },

  menuButton: {
    backgroundColor: "transparent",
    width: "24px",
    height: "24px",
    cursor: "pointer",
    padding: "unset",
    border: "none",
    minWidth: "0",

    "& > img": {
      width: "100%",
      height: "100%",
    },
  },

  logo: {
    width: "52px",

    "& > img": {
      width: "100%",
    },
  },

  searchFilter: {
    height: "52px",
    width: '400px',
    borderRadius: "12px",
    backgroundColor: "#F0FAFA",
    [`@media (min-width:1920px)`]: {
      width: '768px'
    },
    [`@media (max-width:1366px)`]: {
      width: '546px'
    },
  },

  headerRightSide: {
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
    gap: "24px",
  },


  notificationsButton: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "24px",
    height: "24px",
    cursor: "pointer",
  },

  userAvatar: {
    width: "40px",
    height: "40px",
    borderRadius: "50%",

    "& > img": {
      width: "100%",
    },
  },

  avatar: {
    width: "100%",
    height: "100%",
    borderRadius: "24px",
  },

  main: {
    marginTop: "25px",
    display: "flex",
    flexWrap: "nowrap",
    position: "relative",
    padding: "0px 35px",
    justifyContent: "space-between",
  },

  cardContainer: {
    display: "flex",
    padding: "25px 30px 25px 30px",
    justifyContent: "space-between",
    borderRadius: "10px",
    boxShadow: "0px 0px 4px 0px #8989895C",
  },

  cardPanel: {
    boxShadow: "none",
    display: 'flex',
    flexDirection: 'column'
  },
  progressMenuContainer: {
    display: "flex",
    alignItems: "center",
  },

  progressMenu: {
    padding: "6px 14px 6px 14px",
    gap: "4px",
    borderRadius: "26px",
    height: "32px",
    content: "none",

    "& .MuiSelect-selectMenu": {
      overflow: "initial",
    },

    "& .MuiSelect-select": {
      padding: 0,
    },

    "&::before": {
      content: "none",
    },

    "&::after": {
      content: "none",
    },

    "&:hover": {
      content: "none",
    },
  },
  inProgress: {
    color: "#346472",
    border: "1px solid #346472",
  },

  completed: {
    color: "#26BBB3",
    border: "1px solid #26BBB3",
  },

  deleted: {
    color: "#C43937",
    border: "1px solid #C43937",
  },

  issuesSentToDealer: {
    color: "#FCCC0F",
    border: "1px solid #FCCC0F",
  },

  resolvedInDealerReview: {
    color: "#4B4C4B",
    border: "1px solid #4B4C4B",
  },

  dealerFlagsIncomplete: {
    color: "#F05E22",
    border: "1px solid #F05E22",
  },

  mainLeftSide: {
    width: "70%",
    marginRight: "26px",
  },

  mainRightSide: {
    width: "30%",
  },

  hireSPSection: {
    padding: "35px 24px",
    borderRadius: "10px",
    boxShadow: "0px 0px 4px 0px #8989895C",
    background: "#FFFFFF",

    "& .MuiAccordionSummary-root": {
      padding: 0,
      height: "20px",
      minHeight: "0",
    },

    "& .MuiAccordionDetails-root": {
      padding: "0",
    },
  },
  findProviderBtn: {
    marginTop: "30px",
    width: "100%",
    height: "44px",
    background: "#013D4F !important",
    borderRadius: "8px",
    fontSize: 12,
    [`@media (min-width:1920px)`]: {
      height: "56px",
      fontSize: 14,
    },
    "& > span": {
      textTransform: "initial",
    },
  },

  disabledBtn: {
    background: "#E8F8FD !important",
    color: "#A3E2F5 !important",
  },

  documentAddOns: {
    padding: "35px 30px 35px 30px",
    borderRadius: "10px",
    boxShadow: "0px 0px 4px 0px #8989895C",
    background: "#F1F4F3CC",
  },

  addOns: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    gap: "30px",
  },

  addOn: {
    padding: "20px",
    borderRadius: "10px",
    background: "#FFFFFF",
    boxShadow: "0px 0px 12px 0px #C3C3C330",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
  },

  firstAddOn: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  secondAddOn: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  buyNowBtn: {
    marginTop: "26px",
    width: "100%",
    background: "#4FC8EC",
    borderRadius: "8px",
    textTransform: "initial",
    fontWeight: 700,
    height: 32,
    fontSize: 12,
    [`@media (min-width:1920px)`]: {
      height: 44,
      fontSize: 16,
    },
    "&:hover": {
      background: "#4FC8EC",
    },
    "&:disabled": {
      background: "#E8F8FD !important",
      color: "#A3E2F5 !important",
    }
  },
  formButton: {
    fontFamily: "Roboto",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "18px",
    textAlign: "left",
    color: "#4FC8EC",
    [`@media (min-width:1920px)`]: {
      fontSize: 16,
    },

  },
  purchasedText: {
    fontFamily: "Roboto",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "18px",
    textAlign: "left",
    color: "#939F9B",
    [`@media (min-width:1920px)`]: {
      fontSize: 16,
    },

  },
  inProgressText : {
    fontFamily: "Roboto",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "18px",
    textAlign: "left",
    color: "#F05E22",
    [`@media (min-width:1920px)`]: {
      fontSize: 16,
    },
  },
  documentsSection: {
    marginTop: "25px",
    marginBottom: "174px",
    padding: "40px 30px 35px 30px",
    height: "1008px",
    gap: "30px",
    borderRadius: "10px",
    background: "#FFFFFF",
    boxShadow: "0px 0px 4px 0px #8989895C"
  },

  documentSectionContent: {
    marginTop: "30px", display: "flex", justifyContent: "space-between", alignItems: "center"
  },

  leftSideSection: {
    width: '70%',
    height: "880px",
    borderRadius: "20px",
    border: "1px solid #F1F4F3",
    padding: "40px 24px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },

  dragAndDrop: {
    marginTop: "8px",
    fontFamily: "Gotham, sans-serif",
    fontSize: "24px",
    fontWeight: 300,
    lineHeight: "32px",
    letterSpacing: "-0.005em",
    textAlign: "center"
  },

  allowableStatement: {
    marginTop: "8px",
    fontFamily: "Roboto",
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "18px",
    textAlign: "left",
    color: "#4B4C4B"
  },

  orStatement: {
    marginTop: "8px",
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "22px",
    textAlign: "center"
  },

  uploadTitle: {
    color: "#C5CBC9",
    fontWeight: 300,
    fontSize: "20px",
    lineHeight: "18px",
    fontFamily: "Gotham, sans-serif",
  },

  rightSideSection: {
    marginLeft: "30px",
    height: "890px",
    borderRadius: "10px",
    border: "1px solid #E5E6E5",
    padding: "35px 30px",
    overflow: "auto"
  },

  basicInfoTypo: {
    fontSize: "18px",
    marginBottom: "30px",
    lineHeight: "18px"
  },

  basicInfoValue: {
    fontSize: "20px",
    fontWeight: 500,
    marginLeft: "10px",
    color: "#4B4C4B"
  },

  boxInfoText: {
    fontSize: 12,
    color: "#4B4C4B",
    lineHeight: "20px",
    textAlign: "left",
    fontWeight: 500,
    fontFamily: 'Roboto',
    [`@media (min-width:1920px)`]: {
      fontSize: 16,
    },
  },
  boxInfoTextHeading: {
    fontSize: 14,
    color: "#013D4F",
    lineHeight: "20px",
    textAlign: "left",
    fontWeight: 500,
    fontFamily: 'Roboto',
    [`@media (min-width:1920px)`]: {
      fontSize: 16,
    },
  },

  documentUploadDialog: {
    "& > div:nth-child(3) > div:nth-child(1)": {
      height: "450px",
      width: "600px",
      borderRadius: "8px",
      background: "#FFFFFF",
      boxShadow: "none",
      margin: 0,
      display: "block",
      flex: "none",
    }
  },

  documentUploadDialogHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "16px 16px 12px 0px",
  },

  closeIcon: {
    width: "32px",
    height: "32px",
  },

  documentUploadDialogTitle: {
    padding: 0,
    fontFamily: "Roboto",
    fontSize: "24px",
    fontWeight: 500,
    lineHeight: "32px",
    textAlign: "left",
    color: "rgba(1, 61, 79, 1)",
    letterSpacing: "-0.005em",
  },

  documentUploadDialogContent: {
    padding: "0px 44.5px"
  },

  browseFiles: {
    marginTop: "26px",
    marginBottom: "32px",
    gap: "8px",
    borderRadius: "8px",
    background: "#FFFFFF",
    border: "1px solid #C5CBC9",
    display: "flex",
    justifyContent: "center",
    padding: "11px 24px"
  },

  documentUploadDialogActions: {
    textTransform: "initial",
    padding: "24px 35px",
    borderTop: "1px solid rgba(242, 243, 242, 1)",

    "& > button": {
      padding: "16px 53px",
      gap: "8px",
      borderRadius: "8px",
    },

    "& > button > span": {
      fontFamily: "Roboto",
      fontSize: "16px",
      fontWeight: 700,
      lineHeight: "24px",
      textAlign: "left",
      textTransform: "initial"
    }
  },

  serviceSent: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '20px',
    alignItems: 'center'
  },

  serviceSentText: {
    fontFamily: "Roboto",
    fontSize: "20px",
    fontWeight: 500,
    lineHeight: "18px",
    color: "#C5CBC9"
  },

  serviceProviderHired: {
    display: 'block',
  },

  circularProgressContainer: {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },

  truncate: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "300px",
    display: "inline-block",
  },

  menuItem: {
    borderRadius: '26px',
    padding: '6px 12px',
    margin: '8px',
  },

  checkListContainer: {
    padding: "0px 18px",
    boxShadow: "0px 2px 8px 0px #00000014",
    borderLeft: "1px solid #DEE4E2",
    overflow: 'scroll',
    [`@media (min-width:1920px)`]: {
      padding: "0px 28px",
    },
    width: '30%'
  },

  checkList: {
    display: 'flex',
    flexDirection: 'column',
    gap: '32px'
  },

  checkListItem: {
    display: 'flex',
    gap: '8px',
    alignItems: "center"
  },

  checkListItemText: {
    fontFamily: "Roboto",
    fontWeight: 400,
    fontSize: "14px",
    [`@media (min-width:1920px)`]: {
      fontSize: "18px",
    },
  },

  checkListWrapper: {
    padding: "25px 10px",
    display: 'flex',
    flexDirection: 'column',
    gap: '46px',
    [`@media (min-width:1920px)`]: {
      padding: "50px 10px 30px 10px",

    },
  },

  checkListHeader: {
    borderBottom: "1px solid #F1F4F3",
    paddingBottom: "20px"
  },

  infoBox: {
    display: 'flex',
    flexDirection: 'column',
    gap: "30px",
    paddingTop: "35px",
    paddingRight: "24px",
    paddingBottom: "35px",
    paddingLeft: "24px",
    borderRadius: "10px",
    border: "1px solid #ECF9F9",
    boxShadow: "0px 0px 4px 0px #8989895C"
  },

  infoBoxTitle: {
    fontFamily: "Gotham Book",
    fontWeight: 400,
    fontSize: "20px",
    [`@media (min-width:1920px)`]: {
      fontSize: "24px",
    },
  },

  info: {
    display: 'flex',
    flexDirection: 'column',
    gap: '15px'
  },

  infoTitle: {
    fontFamily: "Roboto",
    fontWeight: 500,
    fontSize: "16px",
    [`@media (min-width:1920px)`]: {
      fontSize: "20px",
    },
  },

  infoContent: {
    fontFamily: "Roboto",
    fontWeight: 500,
    fontSize: "14px",
    color: "#989A98",
    [`@media (min-width:1920px)`]: {
      fontSize: "18px",
    },
  },

  closeContainer: {
    textAlign: "end",
  },

  checklistTitle: {
    fontFamily: "Gotham light",
    fontSize: "22px",
    fontWeight: 200,
    lineHeight: "18px",
    color: "#013D4F",
    display: "flex",
    alignItems: "center",
    gap: "10px",
    [`@media (min-width:1920px)`]: {
      fontSize: "28px",
    },
  },

  checklistSubtitle: {
    fontFamily: "Gotham Book",
    fontSize: "14px",
    fontWeight: 400,
    color: "#4B4C4B",
    marginTop: '13px',
    [`@media (min-width:1920px)`]: {
      fontSize: "18px",
    },
  },

  backDrop: {
    backdropFilter: "blur(3px)",
    backgroundColor: "rgba(0,0,30,0.4)",
  },

  dialog: {
    "& > div:nth-child(3) > div:nth-child(1)": {
      width: "600px",
      gap: "40px",
      borderRadius: "8px",
      opacity: "0px",
      background: "#FFFFFF",
      boxShadow: "none",
      margin: 0,
      display: "block",
      flex: "none",
    },
  },

  dialogHeader: {
    padding: "24px 16px 24px 40px",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    width: "544px",
  },

  dialogTitle: {
    fontFamily: "Roboto",
    fontSize: "24px",
    fontWeight: 700,
    lineHeight: "32px",
    letterSpacing: "-0.005em",
    color: "#013D4F",
  },

  dialogContent: {
    padding: "0px 40px",
    marginBottom: "55px",
  },

  dialogContentText: {
    fontFamily: "Roboto",
    fontSize: "18px",
    fontWeight: 400,
    lineHeight: "26px",
    textAlign: "left",
    color: "#4B4C4B",
    marginTop: "16px",
  },

  dialogActions: {
    textTransform: "initial",
    padding: "24px 35px",
    borderTop: "1px solid rgba(242, 243, 242, 1)",

    "& > button": {
      height: "56px",
      padding: "16px",
      gap: "8px",
      borderRadius: "8px",
    },

    "& > button > span": {
      fontFamily: "Roboto",
      fontSize: "16px",
      fontWeight: 700,
      lineHeight: "24px",
      textAlign: "left",
      textTransform: "initial",
    },
  },

  cancelBtn: {
    border: "1px solid rgba(76, 77, 76, 1)",

    "& > span": {
      color: "#4C4D4C",
    },
  },

  changeStatusBtn: {
    background: "#4FC8EC",
    "&:hover": {
      background: "#4FC8EC",
    },

    "&.Mui-disabled": {
      background: "#E8F8FD",
      "& > span": {
        color: "#94A3B8",
      },
    },

    "& > span": {
      color: "#FFFFFF",
    },
  },

  closeChecklistTooltip: {
    position: 'absolute',
    top: '75px',
    marginLeft: '-50px',
    boxShadow: '0px 2px 8px 0px #00000014',
    borderRadius: '30px',
    background: '#FFFFFF'
  }
});

const customArrowIcon = () => {
  return (
    <SvgIcon style={{ width: "20px", height: "20px" }}>
      <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z"></path>
    </SvgIcon>
  );
};



// Customizable Area End

export class ProjectTemplatesDealDashboard extends ProjectTemplatesDealDashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  CustomAutocomplete = styled(Autocomplete)({
      borderRadius: 12,
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderColor: "#F0FAFA",
        },
        "&:hover fieldset": {
          borderColor: "#F0FAFA",
        },
        "&.Mui-focused fieldset": {
          borderColor: "#F0FAFA",
        },
      },
    });

  CustomTextField = styled(TextField)({
    "& .MuiInputBase-input::placeholder": {
      color: "#4B4C4B", 
      fontSize: "16px", 
      fontWeight: "500",  
      opacity: 1
    },
  })

  getProgressStyle = () => {
    const { selectedStatus } = this.state;
    const { classes } = this.props;
    let statusClass = "";
    if (selectedStatus === this.inProgress) {
      statusClass = classes.inProgress;
    } else if (selectedStatus === this.completed) {
      statusClass = classes.completed;
    } else if (selectedStatus === this.deleted) {
      statusClass = classes.deleted;
    } else if (selectedStatus === this.issuesSentToDealer) {
      statusClass = classes.issuesSentToDealer;
    } else if (selectedStatus === this.resolvedInDealerReview) {
      statusClass = classes.resolvedInDealerReview;
    } else if (selectedStatus === this.dealerFlagsIncomplete) {
      statusClass = classes.dealerFlagsIncomplete;
    }
    return `${classes.progressMenu} ${statusClass}`;
  };

  renderDealStatus = () => {
    const { classes } = this.props;
    const { selectedStatus, hiredSPInfo, user } = this.state;
    const { inProgress, issuesSentToDealer, resolvedInDealerReview, completed, dealerFlagsIncomplete, deleted } = this;
    const isSPHired = "serviceProviderName" in hiredSPInfo;
    return (
      <Box className={classes.progressMenuContainer}>
        <Select
          data-test-id="status-select-box"
          id="demo-simple-select"
          className={this.getProgressStyle()}
          value={selectedStatus}
          IconComponent={customArrowIcon}
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            getContentAnchorEl: null,
          }}
        >
          <MenuItem
            className={clsx(classes.inProgress, classes.menuItem)}
            data-test-id="in-progress-status"
            value={inProgress}
            onClick={() => {
              this.handleChangeSelectedStatus(inProgress);
            }}
          >
            {inProgress}
          </MenuItem>
          {isSPHired &&
            <MenuItem
              className={clsx(classes.menuItem, classes.issuesSentToDealer)}
              data-test-id="issues-sent-to-dealer-status"
              value={issuesSentToDealer}
              onClick={() => {
                this.handleChangeSelectedStatus(issuesSentToDealer);
              }}
              disabled={true}
            >
              {issuesSentToDealer}
            </MenuItem>
          }
          {isSPHired &&
            <MenuItem
              className={clsx(classes.menuItem, classes.resolvedInDealerReview)}
              data-test-id="resolved-in-dealer-view-status"
              value={resolvedInDealerReview}
              onClick={() => {
                this.handleChangeSelectedStatus(resolvedInDealerReview);
              }}
              disabled={user?.role?.includes('dealer')}
            >
              {resolvedInDealerReview}
            </MenuItem>
          }
          <MenuItem
            data-test-id="completed-status"
            className={clsx(classes.menuItem, classes.completed)}
            value={completed}
            onClick={() => {
              this.handleChangeSelectedStatus(completed);
            }}
            disabled={isSPHired}
          >
            {completed}
          </MenuItem>
          {isSPHired &&
            <MenuItem
              data-test-id="dealer-flags-incomplete-status"
              className={clsx(classes.dealerFlagsIncomplete, classes.menuItem)}
              value={dealerFlagsIncomplete}
              onClick={() => {
                this.handleChangeSelectedStatus(dealerFlagsIncomplete);
              }}
              disabled={true}
            >
              {dealerFlagsIncomplete}
            </MenuItem>
          }
          <MenuItem
            data-test-id="deleted-status"
            className={clsx(classes.menuItem, classes.deleted)}
            value={deleted}
            onClick={() => {
              this.handleChangeSelectedStatus(deleted);
            }}
            disabled={isSPHired}
          >
            {deleted}
          </MenuItem>
        </Select>
      </Box>
    )
  }
  spHired = () => {
    const { sentServiceProviders, hiredSPInfo, user, dealInfo } = this.state
    const isSPHired = "serviceProviderName" in hiredSPInfo;
    const { classes } = this.props

    return (
      <>
        {
          isSPHired ?
            <ServiceProviderHired chatNowAction={this.handleChatNow} navigation={this.props.navigation} id={""} data-test-id="service-provider-hired" serviceProvider={hiredSPInfo} user={user} dealInfo={dealInfo} />
            : (
              sentServiceProviders.length !== 0 && <>
                <Box className={classes.serviceSent}>
                  <Typography className={classes.serviceSentText}>{`${sentServiceProviders.length} out of 3 Services Sent`}</Typography>
                  <ServiceProviderMenu onSelectAction={() => { }} data-test-id="service-provider-menu" />
                </Box>
                <Box className={classes.serviceProviderList} data-test-id="service-provider-list">
                  {
                    sentServiceProviders.map((serviceProvider, index) => (
                      <ServiceProviderHiring hireNew={this.hireNew} withdrawDeal={this.withdrawDeal} data-test-id="service-provider-hiring" serviceProvider={serviceProvider} key={index} />
                    ))
                  }
                </Box>
              </>)
        }
      </>
    )
  }
  renderPurchasedOptions = (testId: string, transaction_id: string | null) => {
    const { classes } = this.props;
    return (
      <>
        <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
          <Box display={'flex'} alignItems={'center'} style={{ gap: '8px' }}>
            {transaction_id ? <>
            <CheckCircleOutlineRoundedIcon style={{ width: '0.7em', color: '#939F9B' }} />
            <Typography data-test-id={testId} className={classes.purchasedText} component={'p'}>{configJSON.purchased}
            </Typography></> : 
            <>
            <SvgIcon style={{ width: "20px", height: "20px" }}>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path opacity="0.15" fill-rule="evenodd" clip-rule="evenodd" d="M12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" fill="#F05E22"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20C12.5523 20 13 20.4477 13 21C13 21.5523 12.5523 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 12.5523 21.5523 13 21 13C20.4477 13 20 12.5523 20 12C20 7.58172 16.4183 4 12 4Z" fill="#F05E22"/>
              </svg>
            </SvgIcon>
             <Typography data-test-id={testId} className={classes.inProgressText} component={'p'}>{"In Progress"}
             </Typography>
            </>
            }
          </Box>
          <Box display={'flex'} alignItems={'center'} style={{ gap: '8px', cursor: 'pointer' }}>
            {transaction_id ? <><Typography className={classes.formButton} component={'p'}
              data-test-id='open_check_list'
              onClick={this.handleOpenCheckList}
            >
              {configJSON.openChecklist}
            </Typography>
            <ArrowForwardIosIcon style={{ width: '0.7em', color: '#4FC8EC' }} />
             </>:
             <><Typography className={classes.formButton} component={'p'}
             data-test-id='open_check_list'
             onClick={this.navigateToAutoForm}
           >
             {"Get checklist"}
           </Typography>
           <ArrowForwardIosIcon style={{ width: '0.7em', color: '#4FC8EC' }} />
            </>
            }
          </Box>
        </Box>
      </>
    )
  }

  renderMainRightSide = () => {
    const { dealInfo, user, hiredSPInfo, isChecklistOpened, sentServiceProviders } = this.state;
    const { classes } = this.props;
    const isSPHired = "serviceProviderName" in hiredSPInfo;

    return (
      <Box style={{display: isChecklistOpened ? 'none': 'block'}} className={classes.mainRightSide}>
        <RightSideAccordion
          className={classes.hireSPSection}
          defaultExpanded={true}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            {isSPHired ?
              <Box>
                <Typography
                  style={{ textTransform: "uppercase" }}
                >
                  {user?.role?.includes("dealer") ? "Service Provider" : "Dealership"}
                </Typography>
              </Box>
              :
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography
                  style={{ marginRight: "10px", lineHeight: "32px" }}
                  variant="h1"
                >
                  Hire a Service Provider
                </Typography>
                <CustomInfoComponent>
                  <p className={classes.boxInfoText}>
                    We can connect you to a service provider that can handle
                    the title and registration process for your state. This
                    can include submitting paperwork and delivering indicia.
                  </p>
                </CustomInfoComponent>
              </Box>
            }
          </AccordionSummary>
          <AccordionDetails style={{ flexDirection: 'column' }}>
            <Box className={classes.serviceProviderHired}>
              {this.spHired()}
            </Box>
            {!isSPHired && <Button
              variant="contained"
              color="primary"
              disableElevation
              disabled={sentServiceProviders.length === 3}
              className={`${classes.findProviderBtn} ${sentServiceProviders.length === 3 && classes.disabledBtn}`}
              data-test-id='find-a-provider-btn'
              onClick={this.navigateToServiceProviderDashboards}
            >
              Find a Provider
            </Button>}
          </AccordionDetails>
        </RightSideAccordion>
        <RegistrationFee
          data-test-id="registration-fee-component"
          role={user.role}
          hiredSPInfo={hiredSPInfo}
          calculateNowHandler={this.navigateToOrderSummaryCalculateRegistrationFee}
          sendRegistrationFee={this.sendRegistrationFee}
          registrationFee={this.state.registrationFee}
          registrationFeeStatus={this.state.registrationFeeStatus}
          isDealCompletedOrDeleted={this.isDealCompletedOrDeleted()}
        />

        <DealDashboardTaskList isDealCompletedOrDeleted={this.isDealCompletedOrDeleted()} id="" navigation="" />

        <DealDashboardNote isDealCompletedOrDeleted={this.isDealCompletedOrDeleted()} isSPHired={isSPHired} dealInfo={dealInfo} id="" navigation="" />
      </Box>
    )
  }

  renderLeftRightSide = () => {
    const { dealInfo, user, hiredSPInfo, isChecklistOpened } = this.state;
    const { classes } = this.props;
    const formatMoney = (number: number) => {
      return number.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
    }

    const ToolTip = withStyles({
      tooltip: {
        padding: '16px',
        radius: '8px',
        background: '#FFFFFF',
        boxShadow: "0px 8px 32px 0px #0000000F, 0px 4px 8px 0px #00000008, 0px 25px 50px 0px #00000017",
        color: '#4B4C4B',
        fontSize: '15px'
      },
    })(Tooltip);

    const isSPHired = "serviceProviderName" in hiredSPInfo;
    let basicInfos: { [key: string]: any } = [];
    let cardPanelInfos: { [key: string]: any } = [];

    if (dealInfo.vehicle) {
      const {
        vehicleVin,
        vehicleType,
        make,
        odometer,
        bodyType,
        vehicleYear,
        vehicleWeightEmpty,
        vehicleWeightGross,
      } = dealInfo.vehicle;
      const {
        dealId,
        fromState,
        toState,
        applicationType,
        firstOwner,
        secondOwner,
        ownerFirstName,
        ownerLastname,
        saleType,
        soldAs,
        msrp,
        tsp,
        dateOfSale
      } = dealInfo;
      basicInfos = {
        VIN: vehicleVin,
        STATES: fromState + " > " + toState,
        "VEHICLE TYPE": vehicleType,
        "APPLICATION TYPE": applicationType,
        "FIRST OWNER": firstOwner,
        "SECOND OWNER": secondOwner,
        MAKE: make,
        ODOMETER: odometer,
        "BODY TYPE": bodyType,
        YEAR: vehicleYear,
        "OWNER'S NAME": ownerFirstName + " " + ownerLastname,
        "WEIGHT - EMPTY": vehicleWeightEmpty + " lbs",
        "WEIGHT - GROSS": vehicleWeightGross + " lbs",
        MSRP: !isNaN(+msrp) && "$ " + formatMoney(+msrp),
        TSP: !isNaN(+tsp) && "$ " + formatMoney(+tsp),
        "SALE TYPE": saleType,
        "SOLD AS": soldAs,
        "DATE OF SALE": dateOfSale
      };
      cardPanelInfos = {
        "DEAL ID": dealId,
        "OWNER'S LAST NAME": ownerLastname,
        STATES: fromState + " > " + toState,
        APPLICATION: applicationType,
      };
    }
    return(
      <Box className={classes.mainLeftSide} style={{width: isChecklistOpened ? '100%' : '70%'}}>
        <Box>
          <Box className={classes.cardContainer}>
            {Object.entries(cardPanelInfos).map(([key, value]) => (
              <Card className={classes.cardPanel} key={key}>
                <Typography variant="caption">{key}</Typography>
                <ToolTip title={value} placement="top-end">
                  <Typography variant="body2" className={classes.truncate}>{value}</Typography>
                </ToolTip>
              </Card>
            ))}
            {this.renderDealStatus()}
          </Box>
        </Box>
        <Box style={{ marginTop: "25px" }}>
          <Accordion1
            defaultExpanded={true}
            // className={classes.documentAddOns}
            data-test-id="document-add-ons"
          >
            <AccordionSummary1
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              style={{ padding: 0 }}
            >
              <Typography variant="h1"> {configJSON.documentAddOns}</Typography>
            </AccordionSummary1>
            <AccordionDetails style={{ padding: "0px" }}>
              <Box className={classes.addOns}>
                <Box className={classes.addOn} sx={{ width: "30%" }}>
                  <Box className={classes.firstAddOn}>
                    <Typography
                      variant="body1"
                    >
                      {configJSON.prePopulatedFormsAndDocumentChecklist}
                    </Typography>
                    <CustomInfoComponent>
                      <Box>
                        <Typography
                          variant="h6"
                        >
                          Pre-polulated Forms
                        </Typography>
                        <p className={classes.boxInfoText}>
                          All pre populated fillable forms that are
                          required to meet your states registration
                          requirements.
                        </p>
                      </Box>
                      <Box>
                        <Typography
                          variant="h6"
                        >
                          Document Checklist
                        </Typography>
                        <p className={classes.boxInfoText}>
                          A list of all documents required to meet your
                          states registration requirements.
                        </p>
                      </Box>
                    </CustomInfoComponent>
                  </Box>
                  {!this.state.isPopulatedFormsPurchased ? <Button
                    disabled={this.isDealCompletedOrDeleted()||!this.state.buyNowBtnStatus.populatedBlankForms}
                    data-test-id="prepolulated-pay-now-btn"
                    variant="contained"
                    color="primary"
                    disableElevation
                    className={classes.buyNowBtn}
                    onClick={this.navigateToOrderSummary}
                  >
                    Buy Now
                  </Button> :
                    this.renderPurchasedOptions('populated_purchased', this.state.dealInfo.transactionID)

                  }
                </Box>
                <Box className={classes.addOn} sx={{ width: "30%" }}>
                  <Box className={classes.secondAddOn}>
                    <Typography
                      variant="body1"
                      style={{ marginRight: "20px" }}
                    >
                      {configJSON.blankForms}
                    </Typography>
                    <CustomInfoComponent>
                      <Box>
                        <Typography
                          variant="h6"
                        >
                          Blank Forms
                        </Typography>
                        <p className={classes.boxInfoText}>
                          Fillable forms that are required to meet your
                          states registration requirements.
                        </p>
                      </Box>
                    </CustomInfoComponent>
                  </Box>
                  {!this.state.isFormsCentsPurchased ? <Button
                    disabled={this.isDealCompletedOrDeleted()|| this.state.isPopulatedFormsPurchased}
                    data-test-id="blank-form-pay-now-btn"
                    variant="contained"
                    color="primary"
                    disableElevation
                    className={classes.buyNowBtn}
                    onClick={this.navigateToOrderSummaryBlankFormFee}

                  >
                    Buy Now
                  </Button> :
                    this.renderPurchasedOptions('blank_purchased', this.state.dealInfo.transactionID)

                  }
                </Box>
                <Box className={classes.addOn} sx={{ width: "30%" }}>
                  <Box data-test-id="document-check-list-addon" className={classes.secondAddOn}>
                    <Typography
                      variant="body1"
                      style={{ marginRight: "20px" }}
                    >
                      {configJSON.documentChecklistOnly}
                    </Typography>
                    <CustomInfoComponent>
                      <Box>
                        <Typography
                          variant="h6"
                        >
                          Document Checklist
                        </Typography>
                        <p className={classes.boxInfoText}>
                          A list of all documents required to meet your
                          states registration requirements.
                        </p>
                      </Box>
                    </CustomInfoComponent>
                  </Box>
                  {!this.state.isFormsChecklistPurchased ? <Button
                    disabled={this.isDealCompletedOrDeleted() || this.state.isFormsCentsPurchased || this.state.isPopulatedFormsPurchased}
                    data-test-id="checklist-pay-now-btn"
                    variant="contained"
                    color="primary"
                    disableElevation
                    className={classes.buyNowBtn}
                    onClick={this.navigateToOrderSummaryChecklistFee}
                    style={{}}
                  >
                    Buy Now
                  </Button>
                    :
                    this.renderPurchasedOptions('checklist_purchased', this.state.dealInfo.transactionID)
                  }
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion1>
        </Box>
        {dealInfo.dealDocuments !== undefined &&
          <ProjectTemplatesDocumentsUpload
            isSPHired={isSPHired}
            user={user}
            basicInfos={basicInfos}
            id=""
            navigation=""
            documents={dealInfo.dealDocuments}
            getDealInformation={this.getDealInformation}
            fetchDocuments={this.getDealInformation}
            accepted_selected_service_provider_id={this.state.dealInfo.accepted_selected_service_provider_id}
            manageReteriewDocumnet={this.manageReteriewDocumnet}
            isDealCompletedOrDeleted={this.isDealCompletedOrDeleted()}
            navigateToAutoForm={this.navigateToAutoForm}
          />
        }
      </Box>
    )
  }
  isDealCompletedOrDeleted = () => {
    const { selectedStatus } = this.state;
    return selectedStatus === 'Deleted' || selectedStatus === 'Completed';
  };

  // Customizable Area End
  render() {
    // Customizable Area Start
    const { isChecklistOpened, sidebar, isLoading, isModalShown } = this.state;
    const { classes } = this.props;
  

    const CustomCheckbox = styled(Checkbox)(({ theme }) => ({
      'padding': 0,
      '&.Mui-checked': {
        color: '#26BBB3 !important',
        '&:hover': {
          color: '#26BBB3 !important',
        },
      },
      '&.MuiCheckbox-root': {
        color:'rgba(228, 231, 230, 1)',
        '& .MuiIconButton-label': {
          '& .MuiSvgIcon-root': {
            width: '32px',
            height: '32px'
          }
        }
      },
    }));
    return (
      <ThemeProvider theme={theme}>
        <Box sx={{display: 'flex', overflow: 'auto'}}>
          <Box className={classes.layout} style={{width: isChecklistOpened ? '70%' : '100%'}}>
            <Box className={classes.container}>
              <Box className={classes.header}>
                <Box className={classes.headerLeftSide}>
                  <Box className={classes.headerMenuLogo}>
                    <ThemeProvider theme={themeNew}>
                      <HamburgerMenuFull currentDealId={+this.state.dealInfo.dealId} ownerLastName={this.state.dealInfo.ownerLastname} navigation={this.props.navigation} id={""} data-test-id="hamburgerMenuTestId" />
                    </ThemeProvider>

                    <Box className={classes.logo}>
                      <img src={vitu} alt="vitu logo" />
                    </Box>
                  </Box>
                <this.CustomAutocomplete
                      className={classes.searchFilter}
                      options={this.state.deals.map(
                        (deal) => `${deal.label}/ ${deal.id}`
                      )}
                       data-test-id="searchInDealstestId"
                      onChange={(_, value) => this.onDealSelect(value)} 
                      loading={this.state.loadingSearch}
                      renderInput={(params) => (
                        <this.CustomTextField
                          {...params}
                          variant="outlined"
                          placeholder={this.state.dealInfo?.ownerLastname 
                            ? `${this.state.dealInfo.ownerLastname}/ ${this.state.dealInfo.dealId}`
                            : "" }
                          InputProps={{
                            ...params.InputProps,
                            startAdornment: <SearchIcon style={{ marginRight: 8 }} />
                          }}
                        />
                      )}
                    />
                </Box>
                <Box className={classes.headerRightSide}>
                  <Box data-test-id="">
                    <Chat navigation={this.props.navigation} id="" />
                  </Box>
                  <Box
                    data-test-id="notifications"
                    className={classes.notificationsButton}
                  >
                    <Pushnotifications navigation={this.props.navigation} id="" />

                  </Box>

                  <ProfileDropdown navigation={this.props.navigation} id={""} data-test-id="profileDropdownTestId" />
                </Box>
              </Box>
              {isLoading ? <Box className={classes.circularProgressContainer}><CircularProgress /></Box> :
                <Box style={{ 
                  overflow: 'scroll'
                }}>
                <Box className={classes.main}>
                  {this.renderLeftRightSide()}
                  {this.renderMainRightSide()}
                </Box>
                </Box>
              }
            </Box>
          </Box>
          {isChecklistOpened && 
          <Box className={classes.checkListContainer}>
            <Box className={classes.closeChecklistTooltip}>
              <IconButton onClick={this.handleOpenCheckList}>
                <ArrowForwardIosIcon/>
              </IconButton>
            </Box>
            <Box className={classes.checkListWrapper}>
              <Box className={classes.checkListHeader}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Typography className={classes.checklistTitle}>
                    Checklist
                  </Typography>
                  <Box className={classes.closeContainer}>
                    <IconButton style={{ padding: 0 }} onClick={this.handleOpenCheckList}>
                      <Close />
                    </IconButton>
                  </Box>
                </Box>
                <Typography className={classes.checklistSubtitle}>
                  This is the list which you will need
                </Typography>
              </Box>
              <Box className={classes.checkList}>
                {this.state.checkListItems.length !== 0 ? this.state.checkListItems.map((item, index) =>
                  <Box className={classes.checkListItem} key={index}>
                    <CustomCheckbox
                      checked={item.checked}
                      data-test-id="checkbox-item"
                      onChange={() => this.handleCheckToggle(index)}
                      icon={<CircleUnchecked />}
                      checkedIcon={<CircleCheckedFilled />}
                    />
                    <Typography className={classes.checkListItemText}>
                      {item.description}
                    </Typography>
                  </Box>
                ) : <></>}
              </Box>
              <Box className={classes.infoBox}>
                <Typography className={classes.infoBoxTitle}>
                  Please Be Aware:
                </Typography>
                <Box className={classes.info}>
                  <Typography className={classes.infoTitle}>
                    Odometer Disclosure Statement
                  </Typography>
                  <Typography className={classes.infoContent}>
                    Due to federal odometer disclosure laws, no person shall sign an odometer disclosure statement as both
                    the transferor and transferee in the same transaction, unless done so with a secured Power of Attorney.
                    If applicable, provide a secured Power of Attorney OR a third party must sign as POA on behalf of the Owner.
                  </Typography>
                </Box>
                <Box className={classes.info}>
                  <Typography className={classes.infoTitle}>
                    Digital or Electronic Signature and Notary Policy
                  </Typography>
                  <Typography className={classes.infoContent}>
                    At this time, Georgia does not accept digital and electronic signatures or remote online notarization from out of state dealers on any documents.
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>}
        </Box>
        {this.state.chatCreated &&
          <ViewChat 
            retreviewReadMessages={() => { }} 
            navigation={this.props.navigation} id={""} 
            chatBoxType={this.state.sendNotes ? "box" : "create_chat"} 
            closeWindow={this.closeChats} 
            chatDetails={this.state.chatDetails} 
            role={this.state.user.role} 
          />
        }
        <RegistrationFeeDrawer open={sidebar} onCloseDrawer={this.closeDrawerHandler} />
        <Dialog
          data-test-id="change-status-dialog"
          open={isModalShown}
          keepMounted
          onClose={() => { this.setState({ isModalShown: false }) }}
          BackdropProps={{
            classes: {
              root: classes.backDrop,
            },
          }}
          className={classes.dialog}
        >
          <Box className={classes.dialogHeader}>
            <ClearIcon
              data-test-id="close-icon"
              onClick={() => { this.setState({ isModalShown: false }) }}
            />
          </Box>
          <DialogContent className={classes.dialogContent}>
            <Typography className={classes.dialogTitle}>
              Change status of deal to In Progress?
            </Typography>
            <Box className={classes.dialogContentText}>
              In order to make changes to this deal, the deal status must be in progress. Please confirm you would like to change the status.
            </Box>
          </DialogContent>
          <DialogActions className={classes.dialogActions}>
            <Button
              data-test-id="cancel-btn"
              className={classes.cancelBtn}
              onClick={() => { this.setState({ isModalShown: false }) }}
            >
              Cancel
            </Button>
            <Button
              data-test-id="change-status-btn"
              className={classes.changeStatusBtn}
              onClick={this.changeDealToInProgress}
            >
              Change Status to In Progress
            </Button>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}
// Customizable Area Start
const themeNew = createTheme({
  typography: {
    fontFamily: "Roboto",

    h1: {
    },

    h2: {
    },

    h3: {
    },

    h6: {
    },

    body1: {
    },

    body2: {
    },
  },
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: "2em",
        color: "yellow",
        backgroundColor: "red"
      },
      arrow: {
        color: "white",

      }
    }
  }
});

const theme = createTheme({
  typography: {
    fontFamily: "Roboto",

    h1: {
      fontFamily: "Gotham, sans-serif",
      fontSize: "18px",
      fontWeight: 400,
      // lineHeight: "18px",
      color: "#013D4F",
      [`@media (min-width:1920px)`]: {
        fontSize: 28,
      },
    },

    h2: {
      fontFamily: "Gotham, sans-serif",
      fontSize: 24,
      fontWeight: 400,
      color: "#013D4F",
      [`@media (max-width:1500px)`]: {
        fontSize: 18
      },
    },

    h3: {
      fontFamily: "Gotham, sans-serif",
      fontSize: "18px",
      fontWeight: 300,
      // lineHeight: "25px",
      color: "#4B4C4B",
    },

    h5: {
      fontFamily: "Roboto",
      fontSize: screen.width <= 1440 ? "13px" : "16px",
      fontWeight: 700,
      // lineHeight: "25px",
      color: "#013D4F",
    },

    h6: {
      fontFamily: "Roboto",
      fontSize: 14,
      fontWeight: 500,
      // lineHeight: "18px",
      textAlign: "left",
      color: "#013D4F",
      [`@media (min-width:1920px)`]: {
        fontSize: 16, // 28px for large screens
      },
    },

    body1: {
      fontFamily: "Roboto",
      fontSize: "12px",
      fontWeight: 500,
      color: "#4B4C4B",
      [`@media (min-width:1920)`]: {
        fontSize: 18
      }
    },

    body2: {
      fontFamily: "Roboto",
      fontSize: 14,
      fontWeight: 600,
      // lineHeight: "31px",
      textAlign: "left",
      [`@media (min-width:1500px)`]: {
        fontSize: 20,
      },
    },
    caption: {
      fontFamily: "Roboto",
      fontSize: 11,
      fontWeight: 500,
      lineHeight: "18px",
      color: "#939F9B",
      [`@media (min-width:1700px)`]: {
        fontSize: 14,
      },
    }
  },

});

export default withStyles(classNames)(ProjectTemplatesDealDashboard);
// Customizable Area End